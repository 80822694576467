<template>
<div>

  <v-card elevation="0"   style="padding: 15px 30px;height: 100%;" >
  </v-card>

  <vcl-facebook  style="margin: 20px" v-if="!pageloading"></vcl-facebook>
  <v-card v-else elevation="0" style="padding: 15px 30px;height: 100%;" >
    <v-row v-if="$store.getters.auth.loggedIn">
      <v-col cols="6" color="border-bottom: solid 1px #ccc">
        <router-link to="/profile" class="rlink"><v-icon >mdi-close</v-icon><span>برگشت به قبل</span></router-link>
      </v-col>
      <v-col cols="2">

      </v-col>
      <v-col cols="4">

        <p @click="dialog3 = true" class="mb-0 mt-0" >
          <v-icon>mdi-logout-variant</v-icon>&nbsp;
          <span>خروج</span>
        </p>
      </v-col>


      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"
          v-if="!sabtkifpool"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#2077d3; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" >ثبت کیف پول شما</span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="justify-content: space-between;flex-direction: column;font-weight: bold;text-align: justify "  >
            <span class="font-medium"> شما در کادر زیر آدرس عمومی کیف پول رمز ارز متیک از شبکه پالیگان را ثبت میکنید </span> <br>
            <br>
            <span class="font-medium">دقت داشته باشید که آدرس را از کیف پولتان کپی کنید تا اشتباها ثبت نشود مسئولیت ثبت اشتباه با شماست </span> <br>
            <br>
            <span class="font-medium"> شما فقط میتوانید یک آدرس ثبت کنید و اگر اشتباه ثبت کنید دیگر امکان ویرایش وجود ندارد </span> <br>
            <br>
            <span class="font-medium"> بعد از اینکه آدرس کیف پول خودتان را ثبت کردید، آدرس کیف پول ما نمایش داده میشود.  </span> <br>
            <br>
            <span class="font-medium"> آدرس کیف پول به فرمت زیر است البته این آدرس یک آدرس تیستی برای نشان داده به شماست و نباید به آن چیزی واریز کنید. مانند آدرس زیر حتما 0x در اول آدرس وجود داشته باشد  </span> <br>
            <br>
            <span class="font-medium"> 0xAdC0a75D27751b362006Be725228388A8D11806A  </span> <br>
            <br>
            <v-text-field
                style="font-size: small;color:#248d29;text-align:center;"
                placeholder="آدرس کیف پول شما"
                v-model="yourwallet"
            ></v-text-field><br>

            <v-btn  @click="inserwallet" :loading="loading" depressed color="#e3a232" height="50" style="width: 100%"> ثبت کیف پول شما در سیستم </v-btn>

          </div>

        </v-card-actions>

      </v-card>

      <v-divider style="margin:5px;"></v-divider>

      <v-card
                class="mx-auto"
                style="width: 100%;text-align: right;"
                v-if="sabtkifpool"
        >
          <v-card-subtitle style="color:#0e0e0e;background-color:#6a737d; font-weight: bolder;text-align: right;width: 100%;">
            <span color="red" >حساب رمزارزی ما در شبکه polygon</span>
          </v-card-subtitle>

          <v-card-actions style="text-align: right;flex-direction: row-reverse !important;" >

            <v-spacer></v-spacer>



            <div  style="text-align: right;font-weight: bold;text-align: justify"  >

              <div  >
                <span class="font-medium">آدرس کیف پول ما حتما با کلیک بر روی کلید زیر آدرس را کپی کنید. توجه داشته باشید اگر به آدرس دیگر یا شبکه ای غیر از پالیگان واریز نمایید مبلغی برای شما منظور نمیشود. </span> <br>
                <br>
                <span class="font-medium">هر روز قبل واریز از همین قسمت و با زدن کلید زیر آدرس را کپی کنید اگر به آدرسهای قبلی بزنید چیزی تایید نخواهد شد با تشکر. </span> <br>
                <br>
                <span class="font-medium" v-if="pricematic>0"> در این لحظه قیمت هر توکن متیک برابر است با  <span style="color: darkred">{{ Math.ceil( pricematic) }}</span> تومان این بدین معنی است که به ازای هر عدد ارز متیک که برای کیف پول ما ارسال شود این مبلغ در حساب شما شارژ خواهد شد البته شما میتوانید مثلا نیمی از توکن را ارسال کنید یا حتی کمتر با توجه به اینکه این شبکه کارمزد بسیار کمی دارد نگران این موضوع هم نباشید </span> <br>
                <br>
                <div  style="margin: auto; width: 50%;"><img  src="../assets/images/wallet.png" width="150px"></div>
                <div  v-if="wallet.length==42" style="text-align:left !important;">
                  <v-text-field
                    v-on:focus="$event.target.select()"
                    ref="clone"
                    style="font-size: small;color:#248d29;text-align:left !important;"
                    readonly
                    :value="wallet"></v-text-field>
                    <v-btn  @click="copy" :loading="loading" color="#e3a232" height="50" style="width: 100%"> کپی کردن آدرس کیف پول ما </v-btn>
                </div>


              </div>

            </div>

          </v-card-actions>

        </v-card>

      <v-divider style="margin:5px;"></v-divider>

      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"
          v-if="sabtkifpool"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#2077d3; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" >راهنمای واریز </span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="justify-content: space-between;flex-direction: column;font-weight: bold;text-align: justify "  >
            <span class="font-medium">ابتدا از کیف پولی که در مرحله قبل ثبت کرده بودید و آدرس ان در زیر وجود دارد به کیف پول ما که در بالا کپی کردید، مقداری دلخواه رمزارز matic در شبکه polygon واریز نمایید. بعد از اینکه مبلغ شما در شبکه پالیگان چند تایید داشت  بر روی کلید زیر کلیک نمایید تا مبلغ وارد کاربری شما شود. </span> <br>
            <br>
            <span class="font-medium">دقت داشته باشید از زدن به صورت تست بر روی کلید زیر خودداری نمایید چون تا زمان تایید نشدن دیگر اجازه تایید تراکنش حتی اگر درست هم باشد را ندارید  </span> <br>
            <br>
            <span class="font-medium"> دقت داشته باشید قبل از تایید مبلغ شما در سایت با کیف پولی که مبلغ انتقال دادید تراکنشی انجام ندهید.   </span> <br>
            <br>
            <span class="font-medium"> حتما باید ارز matic را از شبکه polygon یا همان پالیگان انتقال دهید انتقال این رمز ارز از سایر شبکه ها منجر به سوختن پول شما میشود.   </span> <br>
            <br>
            <v-text-field
                style="font-size: small;color:#248d29;text-align:center;"
                placeholder="آدرس کیف پولی که از آن به کیف پول ما زدید"
                v-model="yourwallet"
                readonly
            ></v-text-field>
            <v-btn  @click="checkcryptoinwallet" :loading="loading" depressed color="#e3a232" height="50" style="width: 100%"> تایید مبلغ در سایت و اتمام واریز </v-btn>

          </div>

        </v-card-actions>

      </v-card >






    </v-row>
  </v-card>

  <vcl-facebook style="margin: 20px"  v-if="!pageloading"></vcl-facebook>
  <v-row v-else justify="center">
    <v-dialog v-model="dialog3" persistent  max-width="390" >

      <v-card>
        <v-card-title style="font-size: 16px;word-break: normal">
         آیا برای خروج از حساب کاربری مطمن هستید؟
        </v-card-title>
        <v-card-text>این عملیات غیر قابل بازگشت میباشد و برای دسترسی دوبار باید لاگین کنید</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="green darken-1" text @click="dialog3 = false">
            خیر منصرف شده
          </v-btn>
          <v-btn color="red darken-1" text @click="logoutApp">
            بله مطمنم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


</div>

</template>
<script>
  import {mapGetters} from 'vuex';
  import {mapMutations} from 'vuex';
  import axios from "axios";
  import { VclFacebook, VclInstagram } from 'vue-content-loading';

  export default {
    name: 'login',
    components:{
      VclFacebook,
      VclInstagram,
      mapGetters,mapMutations},
    data() {
      return {
        wallet:"",
        yourwallet:"",
        price:[],
        pricematic:0,
        loading:false,
        dialog:false,
        dialog2:false,
        dialog3:false,
        usdprice:0,
        sabtkifpool:false,
        pageloading:false,

      }
    },
    mounted() {
      let self = this;
      this.getInfo();

     // self.pageloading=true;

    },
    computed:{
      ...mapGetters(['auth','appToken','baseUrl']),

    },

    methods:{
      ...mapMutations(['logout']),
      copy() {
        this.$refs.clone.focus();
        document.execCommand('copy');
        this.helperToast({
          show:true,
          text:"آدرس کیف پول ما کپی شد",
          color:'green'
        });

      },
      isValidAddress: function (address) {

        //address = address.replace('0x','');
        //console.log(address);
        if (/^(0x){1}[0-9a-fA-F]{40}$/i.test(address)) {
          return true;
        }
        else
          return false;

      },
      inserwallet() {

        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
        formData.append("wallet", self.yourwallet);

        if(self.isValidAddress(self.yourwallet))
        {
          axios.post(this.baseUrl+'/inserwallet.aspx', formData, config)
              .then(function (r) {
                if(r.data.data.result=="1"){
                  self.sabtkifpool=true;
                  self.helperToast({
                    show:true,
                    text:"کیف پول شما به سیستم اضافه شد",
                    color:'green'
                  });
                }
                else
                {
                  self.helperToast({
                    show:true,
                    text:r.data.data.message,
                    color:'red'
                  });
                }
              });
        }
        else
        {
          self.helperToast({
            show:true,
            text:"آدرسی که وارد کرده اید آدرس درست نیست لطفا دقت نمایید",
            color:'red'
          });
        }


      },
      getprice: function (){
          let self = this;
          this.url = `https://api.polygonscan.com/api?module=stats&action=maticprice&apikey=29MRNNVW3E34VFNZM2U1HYJ11WVJA4T4EA`
          axios
              .get(
                  this.url
              )
              .then((response) => {
                //console.log(response.data);
                self.price = response.data;
                if(self.price.status){
                  self.pricematic = (self.price.result.maticusd) * (self.usdprice);

                }
                else
                {
                  self.pricematic = 0;
                  self.helperToast({
                    show: true,
                    text: "در حال حاضر قیمت ارز متیک در دسترس نمی باشد",
                    color: 'red'
                  });
                }
              });
        },
      checkcryptoinwallet() {
        let self = this;
        let firstblock=25047040;
        let confirmations=5;
        let datas=[];
        this.url = `https://api.polygonscan.com/api?module=account&action=txlist&address=`+self.yourwallet+`&startblock=`+firstblock+`&endblock=99999999&page=1&offset=1&sort=desc&apikey=29MRNNVW3E34VFNZM2U1HYJ11WVJA4T4EA`;
        axios
            .get(
                this.url
            )
            .then((response) => {

              datas = response.data.result[0];
              //self.sendtodaraee(datas.value, datas.timeStamp,datas.hash,datas.from,datas.to);
              //console.log(datas);
              //console.log(response.data.status);
              if(response.data.status=="1")
              {
                if(datas.isError=="0") {

                  if (datas.from == self.yourwallet.toLowerCase()) {
                    if (datas.to == self.wallet.toLowerCase()) {
                      if (datas.confirmations >= confirmations) {
                        self.sendtodaraee(datas.value, datas.timeStamp,datas.hash,datas.from,datas.to);
                      }
                      else
                      {
                        self.helperToast({
                          show: true,
                          text: "کمی صبر کنید هنوز تاییدها در شبکه به حدنصاب نرسیده است",
                          color: 'green'
                        });
                      }

                    } else {
                      self.helperToast({
                        show: true,
                        text: "آخرین تراکنش شما به کیف پول ما واریز نشده است با مدیر در تماس باشید",
                        color: 'red'
                      });
                    }
                  }
                  else {
                    self.helperToast({
                      show: true,
                      text: "از این آدرسی که وارد کردید چیزی به کیف پول ما واریز نشده فعلا",
                      color: 'red'
                    });
                  }


                }
                else {
                  self.helperToast({
                    show: true,
                    text: "تراکنش شما با خطا مواجه شده است و تایید نمیشود.",
                    color: 'red'
                  });
                }
              }

            });
      },
      sendtodaraee(value,timestamp,hash,from,to) {

        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
        formData.append("value", value);
        formData.append("timestamp", timestamp);
        formData.append("hash", hash);
        formData.append("from", from);
        formData.append("to", to);

        axios.post(this.baseUrl+'/daraeecrypto.aspx', formData, config)
            .then(function (r) {
              if(r.data.data.result=="1"){

                self.helperToast({
                  show:true,
                  text:"مبلغ به دارایی شما اضافه گشت",
                  color:'green'
                });

              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
            });

      },
      getInfo(){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

        axios.post(this.baseUrl+'/crypto.aspx', formData, config)
            .then(function (r) {
              if(r.data.data.result=="1"){
                self.wallet=r.data.data.wallet;
                self.usdprice=r.data.data.usd;
                if(r.data.data.mywallet=="1")
                {
                  self.yourwallet=r.data.data.address;
                  self.sabtkifpool=true;
                }
                else
                {
                  self.sabtkifpool=false;
                }
                self.getprice();
              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
              self.pageloading=true;
            });

      },
      logoutApp(){
        this.logout();
        this.$router.push({path:'/'})

      },

    },
    watch:{
     /* file_list(v){
        this.param.avatar = URL.createObjectURL(v);
        this.saveAvatar();
      }*/
    }
  }
</script>

<style>
  .header-r{
    text-align: center;
  }
  .header-l p{

  }
  .header-l p span.subtitle{
    font-weight: bold;
    font-size: 13px;
  }

  #gallery {
    margin-top: 10px;
  }
  .fm-preview-img{
    width:  100%;
    height: 100px;
    border-radius: 5px;
    position: relative;
    vertical-align: middle;
    margin-bottom: 5px;
    background: #DDD;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }
  .fm-preview-img img{
    max-width: 100px;
    height: 100%;
    max-height: 100px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .text-error{
    color: coral;
    font-weight: bold;
    line-height: 1;
    font-size: 13px;
  }

  .fileBselectRows{
    width: 100%;
    border:dashed 2px #0084ff;
    border-radius: 5px;
    height: 105px;
    overflow: hidden;
    position: relative;
    z-index: 98;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
  }
  .fileBrows input{
    width: 100%;
    height: 100%;
    border:solid 5px;
    opacity: 0;
    cursor: pointer;
    display: block;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fileBrows span{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    z-index: 9;
    position: absolute;
    display: block;
    color: #0084ff;
    left: 0;
    top:calc(30% - 20px);
  }
  .fileBrows span i{
    font-size: 30px;
    font-style: normal;
  }

</style>







